/* eslint-disable no-param-reassign */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AnalyticsService from '@/services/analytics.service';
import VCalendar from 'v-calendar';
import emitter from '@/eventBus';
import {
  currency,
  moneyDecimal,
  formatDateTime,
  formatDate,
  formatTime,
  formatMonthDay,
  dayOfWeek,
  shortDay,
  formatTimeStamp,
  checkDateTimeFormat,
  capitalizeFirstLetter,
} from '@/utils/formatters';
import { App } from '@vue/runtime-dom';
import router from './router';
import icons from './modules/icons';
import configureMailgo from './utils/mailgo';
import { Configuration } from './types/configuration.d';
import geti18nFromConfig from './i18n/i18n';
import { store } from './store';

export default (app: App, configuration: Configuration) => {
  AnalyticsService.load(configuration, router, app);

  const i18n = geti18nFromConfig();

  app.use(VCalendar, {});
  icons();
  configureMailgo();

  app.config.globalProperties.$filters = {
    currency,
    moneyDecimal,
    formatDateTime,
    formatDate,
    formatTime,
    formatMonthDay,
    dayOfWeek,
    shortDay,
    formatTimeStamp,
    checkDateTimeFormat,
    capitalizeFirstLetter,
  };

  app.use(router);
  app.use(store);
  app.use(i18n);
  app.component('font-awesome-icon', FontAwesomeIcon);
  app.config.globalProperties.$configuration = configuration;
  app.config.globalProperties.emitter = emitter;
};
